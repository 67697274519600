/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/@justinribeiro/lite-youtube@0.6.2/lite-youtube.min.js
 * - /npm/vanilla-lazyload@15.2.0/dist/lazyload.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/jquery-mousewheel@3.1.13/jquery.mousewheel.min.js
 * - /npm/malihu-custom-scrollbar-plugin@3.1.5/jquery.mCustomScrollbar.min.js
 * - /npm/plyr@3.7.2/dist/plyr.min.js
 * - /npm/bootstrap@4.5.3/dist/js/bootstrap.bundle.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
